import $ from 'jquery';
import slick from 'slick-carousel';

function slide () {

    if ($('.js-slider').length) {
        $('.js-slider').slick({
            autoplay: false,
            arrows: false,
            dots: true
        });
    }

}

export {slide};