import $ from 'jquery';
import slick from 'slick-carousel';

function carousel () {

    if ($('.js-carousel').length) {
        $('.js-carousel').slick({
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fa fa-chevron-right"></i></button>',
            arrows: true,
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        dots: true,
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
    if ($('.js-logos-carousel').length) {
        $('.js-logos-carousel').slick({
            infinite: false,
            slidesToShow: 5,
            slidesToScroll: 1,
            prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fa fa-chevron-right"></i></button>',
            arrows: true,
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 3
                    }
                }
            ]
        });
    }
    if ($('.js-cards-carousel').length) {
        $('.js-cards-carousel').slick({
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fa fa-chevron-right"></i></button>',
            arrows: true,
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        dots: true,
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
}

export {carousel};