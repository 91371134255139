import {slide} from './modules/slide';
import {carousel} from './modules/carousel';
import {toggleClass} from './modules/toggleClass';
import {nav} from './modules/nav';
import {lazy} from './modules/lazyload';
import {tabs} from './modules/tabs';
import {svg} from './modules/svg';
import AOS from 'aos';

AOS.init(
    {
        offset: 50
    }
);
slide();
carousel();
toggleClass();
nav();
lazy();
tabs();
svg();
